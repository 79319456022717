<template>
  <div class="defaultStyle">
    <div
      class="content"
      v-loading="loading"
      :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
    >
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/freedelivery' }">{{
          lang_data == 1 ? "免费寄件" : "Free delivery"
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          lang_data == 1 ? "已下单快递" : "The order has been placed by express"
        }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="search mt20">
        <el-input
          size="mini"
          style="width: 200px"
          class="mr10"
          :placeholder="lang_data == 1 ? '请输入关键字' : 'Please Enter'"
          clearable
          v-model="keyword"
          @keyup.enter.native="getlist(1)"
        >
        </el-input>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="getlist(1)"
          >搜索</el-button
        >
      </div>
      <div class="table-con flex-1">
        <el-table
          header-row-class-name="order-table"
          :data="tabledata"
          stripe
          border
        >
          <el-table-column
            :label="lang_data == 1 ? '快递单号' : 'Courier number'"
            prop="tracking_no"
          >
          </el-table-column>
          <el-table-column
            prop="status.value"
            :label="lang_data == 1 ? '状态' : 'State'"
          >
          </el-table-column>

          <el-table-column
            :label="lang_data == 1 ? '下单时间' : 'Place the order of time'"
            prop="add_time"
          >
            <template slot-scope="scope">
              {{
                $moment(scope.row.add_time * 1000).format("YYYY-MM-DD HH:mm")
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="type.value"
            :label="lang_data == 1 ? '类型' : 'Type'"
          >
          </el-table-column>
          <el-table-column
            prop="clinic_name"
            :label="lang_data == 1 ? '委托单位' : 'Clinic'"
          >
          </el-table-column>

          <el-table-column
            :label="lang_data == 1 ? '操作' : 'Action'"
            width="80px"
          >
            <template slot-scope="scope">
              <el-link type="primary" @click="showDetail(scope.row.id)">{{
                lang_data == 1 ? "查看" : "Check"
              }}</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination
          background
          layout="total,prev, pager, next"
          @current-change="getlist"
          :page-size="page.pageSize"
          :current-page="page.currentPage"
          :total="page.totalCount"
        >
        </el-pagination>
      </div>
      <orderDetail
        v-if="currentShow"
        :data="currentId"
        :currentShow="currentShow"
        @back="getlist"
        @close="currentShow = false"
      ></orderDetail>
    </div>
  </div>
</template>
<script>
import orderDetail from "./orderDetail";
export default {
  name: "send_order_list",
  components: { orderDetail },
  mounted() {
    this.getlist();
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      page: {
        totalCount: 0,
        pageSize: 10,
        currentPage: 1,
      },
      tabledata: [],
      date: [],
      keyword: "",
      currentShow: false,
      currentId: "",
      loading: false,
    };
  },
  watch: {
    $route: function () {
      this.getlist();
    },
    project_id: function () {
      this.getlist();
    },
  },
  computed: {},
  methods: {
    getlist(current_page) {
      this.loading = true;
      let params = {
        keyword: this.keyword,
        page_size: this.page.pageSize,
        current_page: current_page ? current_page : this.page.currentPage,
      };
      this.$axios
        .post("/orders/express_free_send_order/getlist", params)
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          } else {
            this.tabledata = res.data.list;
            this.page.totalCount = res.data.total_count;
            this.page.currentPage = res.data.current_page;
          }
          this.loading = false;
        });
    },
    showDetail(id) {
      this.currentShow = true;
      this.currentId = id;
    },
  },
};
</script>
<style lang="scss" scoped>
.defaultStyle {
  width: 100%;
  height: calc(100% - 70px);
  overflow: auto;
  .content {
    width: 1200px;
    margin: 0px auto;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
  }
}
.search {
  margin-bottom: 20px;
}

.page {
  text-align: right;
  margin: 20px 0;
}
</style>