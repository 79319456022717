<template>
  <el-dialog
    :title="
      lang_data == 1 ? '已下单快递详情' : 'Express details have been placed'
    "
    width="700px"
    :visible.sync="currentShow"
    :before-close="close"
    :close-on-click-modal="false"
  >
    <div
      style="min-height: 200px"
      v-loading="loading"
      :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
    >
      <div class="paper">
        <template v-if="detail && !loading">
          <div class="info-group">
            <p>
              <span class="info-title">{{
                lang_data == 1 ? "快递单号：" : "Tracking No.："
              }}</span
              ><b>{{ detail.info.tracking_no }}</b>
            </p>
            <p class="mt10">
              <span class="info-title">{{
                lang_data == 1 ? "寄件人：" : "The sender："
              }}</span>
              <span>
                <b
                  ><span>{{ detail.info.sender_true_name }}</span>
                  <span>{{ detail.info.sender_contact }}</span
                  ><br />
                  {{ detail.info.sender_province }}{{ detail.info.sender_city
                  }}{{ detail.info.sender_county }}
                  {{ detail.info.sender_addr }}</b
                >
              </span>
            </p>
            <p class="mt10">
              <span class="info-title">{{
                lang_data == 1 ? "收件人：" : "The recipient："
              }}</span>
              <span>
                <b
                  ><span>{{ detail.info.recipient_true_name }}</span>
                  <span>{{ detail.info.recipient_contact }}</span
                  ><br />
                  {{ detail.info.recipient_province
                  }}{{ detail.info.recipient_city
                  }}{{ detail.info.recipient_county }}
                  {{ detail.info.recipient_addr }}</b
                ></span
              >
            </p>
            <p class="mt10">
              <span class="info-title">{{
                lang_data == 1 ? "下单时间：" : "Order Time："
              }}</span>
              <b>
                {{
                  $moment(detail.info.add_time * 1000).format(
                    "YYYY-MM-DD HH:mm"
                  )
                }}
              </b>
            </p>
            <p class="mt10">
              <span class="info-title">{{
                lang_data == 1 ? "物流跟踪：" : "Logistics tracking："
              }}</span>
              <template v-if="detail.route_list.length > 0">
                <ul class="route-list">
                  <li v-for="(item, index) in detail.route_list" :key="index">
                    <span>{{ item.accept_time }}</span>
                    <span>{{ item.accept_address }}</span>
                    <span>{{ item.remark }}</span>
                  </li>
                </ul>
              </template>
              <template v-else>
                <div class="no-route">
                  {{
                    lang_data == 1
                      ? "暂无物流信息"
                      : "No logistics information is available"
                  }}
                </div>
              </template>
            </p>
          </div>

          <div class="table-list-group">
            <ul class="case-list">
              <li v-for="(item, index) in detail.order_list" :key="index">
                <div class="col-item w250">{{ item.patient_name }}</div>
                <div class="col-item w150">{{ item.patient_age }}岁</div>
                <div class="col-item w150">{{ item.order_code }}</div>
                <div class="col-item w250">
                  {{ $moment(item.add_time * 1000).format("YYYY-MM-DD HH:mm") }}
                </div>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">{{
        lang_data == 1 ? "关闭" : "Shut down"
      }}</el-button>
      <el-button
        type="primary"
        @click="cancelOrder"
        size="small"
        v-if="auth_cancel"
        >{{ lang_data == 1 ? "取消下单" : "Cancel the order" }}</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "deliveryDetail",
  props: {
    data: {
      type: [Number, String],
      default: "",
    },
    currentShow: [Boolean],
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      detail: null,
      loading: false,
    };
  },
  mounted() {
    this.getDetail();
  },
  computed: {
    auth_cancel: function () {
      return this.detail && this.detail.info && this.detail.info.status.key == 1
        ? true
        : false;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getDetail() {
      this.loading = true;
      let data = {
        express_free_send_id: this.data,
      };
      this.$axios
        .get("/orders/express_free_send_order/getdetail", {
          params: data,
        })
        .then((res) => {
          if (res.code == 1) {
            this.detail = res.data;
          } else {
            this.$message.error(res.msg);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    cancelOrder() {
      this.$confirm("确认要取消下单吗？", "提示").then(() => {
        let data = {
          express_free_send_id: this.data,
        };
        if (this.loading) return;
        this.loading = true;
        this.$axios
          .post("/orders/express_free_send_order/cancelorder", data)
          .then((res) => {
            this.loading = false;
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.getDetail();
              this.$emit("back");
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            this.loading = false;
            console.error(err);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-group {
  line-height: 28px;
  .info-title {
    color: #666666;
    display: inline-block;
    width: 100px;
    margin-right: 0px;
  }
  p {
    display: flex;
  }
  span {
    margin-right: 15px;
  }
}
.case-list {
  margin-top: 20px;
  li {
    display: flex;
    height: 40px;
    border: 1px solid #e1e1e5;
    border-radius: 5px;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
    color: #666666;
    font-size: 15px;
    margin-bottom: 10px;
  }
}
.route-list {
  padding: 8px 30px 30px 31px;
  width: 455px;
  height: 220px;
  background: #f7f8f9;
  margin-top: 5px;
  li {
    color: #ddd;
    position: relative;
    span {
      color: #888;
    }
    &:before {
      content: "";
      position: absolute;
      left: -16px;
      top: 0;
      width: 2px;
      height: 100%;
      border-left: 1px dashed #ddd;
    }
    &:after {
      content: "";
      position: absolute;
      left: -20px;
      top: 50%;
      margin-top: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #ddd;
    }

    &:first-of-type {
      color: #19be6b;
      &:after {
        background-color: #19be6b;
      }
      span {
        color: #19be6b;
      }
    }
  }
}
.no-route {
  padding: 0px 30px 30px 30px;
  text-align: center;
  color: #888;
  // border: 1px solid #ddd;
}
.btn-group {
  text-align: center;
  padding: 20px 0;
}
</style>
